import React, {useState, Fragment} from 'react'
import { useSelector } from 'react-redux';

import apiClient from '../../Request/http-common';
import { useMutation } from "react-query";
import { useNavigate } from 'react-router-dom';
import { removeItem } from './paymentSlice';
import { useDispatch } from 'react-redux';
const Cart = () => {
  const dispatch = useDispatch();
  const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
  const navigate = useNavigate();
  const paymentMethods = useSelector((state) => state.history.payment_methods);
  // const product = useSelector((state) => state.cart.product);
  const items = useSelector((state) => state.cart.items);

  const getTotal = () =>{
    let total = 0;

    if(items.length > 0 ){      
      items.forEach(element => {
        total += parseFloat(element.amount);
      });
    }else{
      total = 0;
    }
    return total;
  }
  
  
  const [payment, setPayment] = useState(null);
  const setPaymentId = (id)=>{
    console.log(id);
    paymentMethods.forEach(payment => {
      if (parseInt(id) === parseInt(payment.id)) {
        setPayment(payment);
      }
    });
  }

  const initialLogin = {
    name: '',
    details: '',

}

  const [error, setErros] = useState('');
  const [generalError, setGeneralError] = useState('');
  const [productForm, setproductForm] = useState(initialLogin)
  const [successMessage, setSuccessMessage] = useState(null);

  const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(
    async () => {
        const formData = new FormData();
        formData.append('amount', getTotal());
        formData.append('method', payment.id);
        formData.append('description', 'Medical Purchases');

      return await apiClient.post(`/api/store-payment`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: (res) => {
        if (res.data.status === 'success') {
          setSuccessMessage(res.data.message);
          setTimeout(() => {
            navigate('/list-payments')
          }, 2000);
        }
      },
      onError: (err) => {   
        if (err.response?.data.hasOwnProperty('errors')) {
          let myerror = err.response?.data.errors || err;         
          setErros(myerror)   
                   
      }else{
          let errorMessage = err.response?.data.message || err ;
          setGeneralError(errorMessage);

          
      }       
             
      },
    }
  );
  return (
    <div>

      {items.length > 0 ?
      <div className="medicine-details-main details-text" style={{marginTop: '40px'}}>
     {items.map(product => (
        <div key={product.created_at} className="medicine-main medicine-cart">
        <div className="medicine-product">
          <div className="medicine-left"><img src={product.image? product.image :product.profile? product.profile:'https://res.cloudinary.com/dzyhsongt/image/upload/v1724859077/call-ambulance-img_fiq8mq.png'} alt /></div>

            <div className="medicine-right">
              <h2><a href="">{product.name ? product.name: product.title}</a></h2>
              <p><strong>{product.description ? product.description: product.category}</strong></p>
              <div className="medicine-price-box">
                <div className="price-left">
                <strong>{formatPrice(product.amount)}</strong>
                </div>                      
              </div>
              <div className="price-left">
                <strong>{product.time}</strong>
              </div>

              <div className="price-left">
                <strong>{product.location}</strong>
              </div>
              <div onClick={()=>dispatch(removeItem(product))} className="delete-icon"><img src="images/delete.png" alt="" /></div>  
            </div>
                    
        </div>
      </div>
     ))}
      





      <div className="payment-summary">
        <h2>Payment Methods</h2>
        <div className="summary-text-box">

          <div className=''>
            <select onChange={(e)=>setPaymentId(e.target.value)} name='payment_method' style={{width: '100%', height: '40px'}}
            
            >
              <option>
                Select Payment Method
              </option>
              {paymentMethods.length > 0 &&
              paymentMethods.map(method => (
                <option key={method.id} value={method.id}
                >
                  {method.name}
                </option>
              ))
              
              }
            </select>
          </div>
              {payment !== null &&
              <Fragment>
                <div className="summary-text">
                  <strong>Payment Method</strong>
                  <span>{payment.name}</span>
                </div>

                <div className="summary-text">
                  {/* <strong>{payment.name}</strong> */}
                  <span>{payment.details}</span>
                </div>
                
              </Fragment>
              
              }               
         
        </div>
            
            {generalError !== '' &&
            <div className='alert alert-danger' style={{color: 'red', marginTop: '20px', textAlign: 'center'}}>
            {(generalError !== '' && generalError.message || generalError) }
          </div>
            }
        
              
            {successMessage !== null &&
              <div className='alert alert-success' style={{ marginTop: '20px', textAlign: 'center'}}>
              {successMessage}
            </div>
            }

        {payment != null &&
        <div style={{padding: '10px'}}>
          <div className='alert alert-info' style={{textAlign: 'center'}}>
          Kindly use the above details to make payment
          </div>
          </div>
          }
        <div className="cart-total-price">
          
            
          <strong>Total</strong>
          <strong>{formatPrice(getTotal())}</strong>
        </div>
      </div>
      <div className="checkout-btn" onClick={postProduct}>
        <a href="#" className="checkout"> {isSendingRequest ? 'processing...' : 'Confirm Payment'}</a>
      </div>
    </div>
      :
        <div className='alert alert-info' style={{marginTop: '80px'}}>
          No item in cart
        </div>
      }
     
    </div>
  )
}

export default Cart
